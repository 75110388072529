import React from 'react'
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles, AppBar, Button, Toolbar, Typography, Tabs, Tab, Drawer, List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";

const styles = theme => ({
    toolbar: {
        backgroundColor: '#FFFBFA',
        paddingLeft:0,
        height: "88px",
    },
    logoContainer: {
        position: "absolute",
        top: "0",
        height: "72px",
        margin:"8px",
        lineHeight:0,
        fontWeight:600,
    },
    title: {
        fontFamily: "'EB Garamond',serif",
        fontSize: "1.6rem",
        color:"#CA465E",
    },
    text: {
        position:"absolute",
        display:"block",
        left:"75px",
        top:"35px",
        width:"140px"
    },
    logo: {
        backgroundImage: "url('/qhfs-logo.png')",
        position:"absolute",
        width:"72px",
        height:"72px",
        left:"0px",
        top:"0px",
        display: "block",
        backgroundSize: "cover",
        textIndent: "100px",
        whiteSpace: "nowrap",
        overflow: "hidden"
    },
    drawer: {
        
    },
    drawerPaper: {
        backgroundColor: '#FFFBFA',
        minWidth: "230px",
        
    },
    drawerList: {
        position:"relative",
        top:"88px"
    },
    portalLogin: {
        textTransform: 'none',
        fontWeight: 300,
        color:"#CA465E",
        textDecoration:"underline",
        position:"absolute",
        top:0,
        right:0
    },
    tabs: {
        backgroundColor:'#FFFBFA',
        borderTop:"1px solid rgba(0, 0, 0, 0.12)",
        borderBottom:"1px solid rgba(0, 0, 0, 0.12)",
    },
    navItem: {
        color: "black"
    },
    navHeader: {
        backgroundColor:'#FFFBFA',
        borderTop:"1px solid rgba(0, 0, 0, 0.12)",
        borderBottom:"1px solid rgba(0, 0, 0, 0.12)",
    }
});


function LinkTab(props) {
    return <Tab component="a" {...props} />;
}

class Header extends React.Component {
    state = {
        drawerOpen: false,
    };
    handleDrawerToggle = () => {
        this.setState(state => ({ drawerOpen: !state.drawerOpen }));
    };
    render() {
        const { classes } = this.props;
        return (
            <AppBar position="static" color="inherit">
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h1" color="inherit" className={classes.logoContainer}>
                        <a href="/" className={classes.title}>
                            <span className={classes.logo}>Q</span><span className={classes.text}>HFS Limited</span>
                        </a>
                    </Typography>
                    <Button color="inherit" className={classes.portalLogin} href="https://www.intelligent-office.net/portal/Portalhome.asp?IO=5DA44B2F-9C73-436B-8833-6A9F1CAD5FD5" component="a">Client Login</Button>
                </Toolbar>
                <Tabs
                indicatorColor="primary"
                textColor="primary"
                color="inherit"
                className={classes.tabs}
                value={false}
                >
                    <Tab icon={<MenuIcon/>} onClick={this.handleDrawerToggle}>
                    </Tab>
                    <LinkTab label="Services" href="/#services"/>
                    <LinkTab label="About" href="/about"/>
                    <LinkTab label="Contact" href="/contact"/>
                </Tabs>
            
                <Drawer anchor="left" open={this.state.drawerOpen} onClose={this.handleDrawerToggle} className={classes.drawer} classes={{
                    paper: classes.drawerPaper,
                    }}>
                    <Typography variant="h1" color="inherit" className={classes.logoContainer}>
                        <a href="/" className={classes.title}>
                            <span className={classes.logo}>Q</span><span className={classes.text}>HFS Limited</span>
                        </a>
                    </Typography>
                    <List className={classes.drawerList}>
                        <ListItem key="about" href="/about" component="a" className={classes.navItem}>
                            <ListItemText primary="About Us"/>
                        </ListItem>
                        <ListItem button key="contact" href="/contact" component="a">
                            <ListItemText primary="Contact Us"/>
                        </ListItem>
                        <ListSubheader className={classes.navHeader}>Services</ListSubheader>
                        <ListItem button key="mortgages" href="/services/mortgages" component="a">
                            <ListItemText primary="Mortgages"/>
                        </ListItem>
                        <ListItem button key="savings" href="/services/investment" component="a">
                            <ListItemText primary="Savings &amp; Investments"/>
                        </ListItem>
                        <ListItem button key="protection" href="/services/protection" component="a">
                            <ListItemText primary="Protection"/>
                        </ListItem>
                        <ListItem button key="retirement" href="/services/retirement" component="a">
                            <ListItemText primary="Retirement"/>
                        </ListItem>
                        <ListItem button key="tax" href="/services/tax" component="a">
                            <ListItemText primary="Tax"/>
                        </ListItem>
                        <ListItem button key="corporate" href="/services/corporate" component="a">
                            <ListItemText primary="Corporate"/>
                        </ListItem>
                        <ListSubheader className={classes.navHeader}>Legal</ListSubheader>
                        <ListItem button key="disclaimer" href="/legal/disclaimer" component="a">
                            <ListItemText primary="Disclaimer"/>
                        </ListItem>
                        <ListItem button key="risk" href="/legal/risk-warning" component="a">
                            <ListItemText primary="Risk Warning"/>
                        </ListItem>
                        <ListItem button key="privacy" href="/legal/privacy-policy" component="a">
                            <ListItemText primary="Privacy Policy"/>
                        </ListItem>
                        <ListItem button key="data-protection" href="/legal/data-protection" component="a">
                            <ListItemText primary="Data Protection"/>
                        </ListItem>
                    </List>
                </Drawer>
            </AppBar>
        )
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Header);