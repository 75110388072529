import React from "react"
import PropTypes from 'prop-types';
import { Card, CardContent, CardMedia, Grid, Typography, withStyles } from "@material-ui/core"
import Layout from '../../components/layout';

const styles = theme => ({
  card: {
    display: "block",
    maxWidth: 320,
    textDecoration: "none"
  },
  media: {
    height: 280,
  },
  cardTitle: {
    textTransform: "uppercase",
    textAlign: "center",
    fontSize: "1rem",
    fontWeight: 600,
  }
});

function Content(props) {
 const {classes} = props;
  return (
    <Layout>
      <h2>About QHFS</h2>

      <p>QHFS Limited was formed in 2003 from Quentin Howe Financial Services that was set up in 2001. It is a local,
        privately owned, independent company offering unbiased and professional financial advice.</p>

      <p><a href='/about/quentin'>Quentin Howe</a> is the Director and an Independent Financial Adviser for QHFS Limited.</p>

      <p>QHFS Limited provides comprehensive and impartial financial advice to personal and corporate customers in their
        home or place of business. We aim to ensure that our clients receive independent advice and an ongoing service
        that is objective, forward thinking and of the very highest standard.</p>

      <p>Our aim is to build long term, mutually beneficial relationships with our clients and we usually work only
        through recommendation by our existing clients, estate agents, solicitors and accountants.</p>

      <h3>The Team</h3>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} md={4}>
          <Card href="/about/quentin" className={classes.card} component="a">
            <CardMedia image="/images/person.jpg" className={classes.media}/>
            <CardContent>
              <Typography component="h5" className={classes.cardTitle}>Quentin Howe</Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card href="/about/mark" className={classes.card} component="a">
            <CardMedia image="/images/person.jpg" className={classes.media}/>
            <CardContent>
              <Typography component="h5" className={classes.cardTitle}>Mark Brown</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <h3>How we work</h3>

      <p>QHFS Limited is well equipped to compete in the financial marketplace.</p>

      <p>We have spent over ten years working through TenetConnect Limited, a network for the professional adviser.</p>

      <p>In September 2013 we left TenetConnect Limited to become directly authorised with the Financial Conduct
        Authority (FCA).  We now report directly to the FCA on a minimum of a six-monthly basis.</p>

      <p>We work with you to achieve your financial objectives and this involves an initial meeting to carry out a
        review of your current financial situation and to establish your aims for the future. Any information
        discussed will be treated in the strictest confidence and this first meeting involves no obligation or cost.</p>

      <p>Further meetings, if appropriate, are then arranged to consider your particular circumstances in greater
        detail and to discuss possible methods of achieving your financial objectives.</p>

      <p>Ideally, your current situation should be reviewed at regular intervals and recommendations adjusted to suit
        your changing circumstances.</p>

      <h3>How we are paid</h3>

      <p>Our fees are open and transparent.</p>

      <p>Our fees are always agreed in advance of any work being undertaken on your behalf or any cost being incurred by you.</p>

      <p>Our fees can be structured in several different ways to suit you, depending on the service or products you
        require. They can be paid directly by you or deducted from any products or services by way of adviser
        charging or there are some products where commission is still paid.</p>
    </Layout>
  );
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);