import React from "react"
import PropTypes from "prop-types"
import { withStyles, Button, Grid } from "@material-ui/core"

const styles = theme => ({
  footer: {
    backgroundColor: "rgb(51,51,51)",
    color: "rgb(150,150,150)",
    fontSize: "0.8rem",
    padding: "16px"
  },
  footerButton: {
    color: "rgb(150,150,150)",
    padding: "0",
    fontSize: "0.8rem",
    textTransform: "none",
    fontWeight: 300,
    lineHeight:"normal",
    paddingBottom:"8px"
  },
  footerLinks: {
    listStyleType: "none",
    textAlign: "right",
  },
  uk: {
    color:'rgb(200,200,200)',
    fontWeight: 800,
    fontSize:'110%'
  }
})

function Footer(props) {
  const { classes } = props
  return (
    <div className={classes.footer}>
      <Grid container spacing={0}>
        <Grid item xs={7} sm={8} md={9} lg={10}>
          <p className={classes.uk}>The guidance and/or advice contained within this website is/are subject to the UK regulatory regime
            and is/are therefore primarily targeted at consumers based in the UK.</p>
          <p>Copyright QHFS Limited © 2006-2019</p>
          <p>QHFS Limited is a company registered in England and Wales with company number 4984846. It's registered
            office is Orchard House,Liverton, Newton Abbot, Devon, TQ12 6HS</p>

          <p>The use of this site is subject to you having read and accepted the Disclaimer &amp; Risk Warning</p>

          <p>QHFS Limited is authorised and regulated by the Financial Conduct Authority.</p>
        </Grid>
        <Grid item xs={5} sm={4} md={3} lg={2}>
          <ul className={classes.footerLinks}>
            <li><Button component="a" href="/legal/disclaimer" className={classes.footerButton}>Disclaimer</Button></li>
            <li><Button component="a" href="/legal/risk-warning" className={classes.footerButton}>Risk Warning</Button>
            </li>
            <li><Button component="a" href="/legal/privacy-policy" className={classes.footerButton}>Privacy
              Policy</Button></li>
            <li><Button component="a" href="/legal/data-protection" className={classes.footerButton}>Data
              Protection</Button></li>
          </ul>
        </Grid>
      </Grid>
    </div>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Footer)