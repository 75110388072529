import React from "react"
import PropTypes from 'prop-types';
import { withStyles } from "@material-ui/core";
import Header from '../components/header';
import Footer from '../components/footer';

const styles = theme => ({
    root: {
        backgroundColor: '#FFFBFA',
    },
    contentContainer: {
        minHeight: "400px",
        padding:"16px",
        backgroundColor: "white"
    }
});

function Layout(props) {
    const {children, classes} = props;
    return (
        <div className={classes.root}>
            <Header/>
            <div className={classes.contentContainer}>
                {children}
            </div>
            <Footer/>
        </div>
    );
}

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Layout);